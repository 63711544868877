<template>
  <div class="conditionsUtilisation" >
    <div class="container">
      <div class="row text-center mt-4">
        <div class="col-12">
          <h2 class="help-title">Conditions générales d'utilisation du Site</h2>
          <em> En vigueur au 27 février 2020 </em>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <p>
            Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des
            modalités de mise à disposition du Site et des services par https://accompagnement-retraite-audiens.org/ (dites "Le Site")
            et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».<br>
            Les présentes CGU sont accessibles sur le Site à la rubrique "Conditions générales d'utilisations".
          </p>
          <p>
            Toute inscription ou utilisation du Site implique l'acceptation sans aucune réserve ni restriction des présentes
            CGU par l’utilisateur. Lors de l'inscription sur le Site via le Formulaire d’inscription, chaque utilisateur
            accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir
            lu et compris les Conditions générales d'utilisation et je les accepte ».
            En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à
            l'accès des services proposés par le Site.
            Le Site se réserve le droit de modifier unilatéralement et à tout moment le contenu des
            présentes CGU.
          </p>

        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 1 : Mentions légales
          </h3>
          <p>
            L'édition du Site est assurée par
            la Société CILVER, SARL, au capital de 1000€, immatriculée au RCS de Paris sous le numéro
            821 208 741  dont le siège social est situé au 36 rue Croix des petits Champs 75001 Paris, sous la commande du
            Groupe Audiens, Association déclarée, immatriculée au RCS de Paris sous le numéro 448 323 759, dont le
            siège social se situe au 74 rue Jean Bleuzen, 92170 Vanves. <br>
            Le Directeur de la publication est Isabelle Thirion, Directrice Développement social et adhérents individuels. <br>

            L'hébergeur du Site est la Société OVH SAS,
            au capital de 10 069 020 €, immatriculée au RCS de Lille Métropole sous le numéro 424 761 419,
            dont le siège social est situé au 2 rue Kellermann 59100 Roubaix, France.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 2 : Accès au Site
          </h3>
          <p>
            L'utilisation du Site est destiné aux salariés des entreprises clientes du Groupe Audiens et qui
            souhaitent un accompagnement dans le cadre de la constitution de bilan retraite individuel.
            Le Site permet à ces utilisateurs un accès gratuit aux services suivants :
              <ul>
                <li> Simulation du test d'éligibilité à un plan de départ à la retraite mis en place par l'entreprise</li>
                <li> La création d'un espace personnel</li>
                <li> L'échange d'informations et de documents permettant à un conseiller Audiens de réaliser le bilan retraite individuel</li>
                <li> La récupération du bilan retraite individuel mis à disposition par le conseiller Audiens</li>
                <li> La prise d'un rendez-vous avec un conseiller Audiens</li>
              </ul>
          </p>
          <p>
            Le Site est toutefois accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
            supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.).
          </p>
          <p>
            L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le
            formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des
            informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email.
            L’Utilisateur est responsable de la mise à jour des informations fournies. Il lui est précisé qu’il peut les
            modifier en se connectant à son espace membre.<br>
            Pour accéder aux services, l’Utilisateur devra s'identifier à l'aide de son email et de son mot de
            passe qu'il définit lors de son inscription et qui sont strictement personnels. A ce titre, il s’en
            interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l’usage qui en sera fait.<br>
            L’Utilisateur pourra également solliciter sa désinscription en envoyant un email à :
            contact@accompagnement-retraite-audiens.org. Celle-ci sera effective dans un délai raisonnable.
            En cas de non respect des conditions générales d’utilisation, le Site
            aura la possibilité de suspendre voire de fermer le compte d’un Utilisateur après mise en demeure adressée
            par voie électronique et restée sans effet.
            Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de toutes
            informations personnelles de l’Utilisateur.
            Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du Site ou
            serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la
            responsabilité du Site. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à
            l’éditeur de toute interruption ou suspension de service, même sans préavis.
            L'Utilisateur a la possibilité de contacter le Site par messagerie électronique à l’adresse contact@accompagnement-retraite-audiens.org.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 3 : Données personnelles
          </h3>
          <p>
            Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie
            privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
            L'Utilisateur peut accéder à notre politique de confidentialité sur la page Politique de Confidentialité.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 4 : Propriété intellectuelle
          </h3>
          <p>
            Les marques, logos, signes ainsi que tous les contenus du Site font l'objet d'une
            protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
            La marque Audiens est une marque déposée. Toute représentation
            et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est
            totalement prohibée.
            L'Utilisateur doit solliciter l'autorisation préalable duSSite pour toute reproduction, publication, copie des
            différents contenus. Il s'engage à une utilisation des contenus du Site dans un cadre strictement privé, toute
            utilisation à des fins commerciales et publicitaires est strictement interdite.
            Toute représentation totale ou partielle de ce Site par quelque procédé que ce soit, sans l’autorisation expresse
            de l’exploitant du Site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du
            Code de la propriété intellectuelle.
            Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui
            reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 5 : Responsabilité
          </h3>
          <p>
            Les sources des informations diffusées sur le Site sont réputées fiables mais le Site
            ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
            Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré
            des mises à jour régulières, le Site ne peut être tenu responsable de la modification
            des dispositions administratives et juridiques survenant après la publication. De même, le Site ne peut être
            tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce Site.
            L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa
            forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le Site décline
            toute responsabilité.
            Le Site ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter
            l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au
            téléchargement provenant de ce Site.
            La responsabilité du Site ne peut être engagée en cas de force majeure ou du fait imprévisible et
            insurmontable d'un tiers.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 6 : Liens hypertextes
          </h3>
          <p>
            Des liens hypertextes peuvent être présents sur le Site. L’Utilisateur est informé qu’en cliquant sur ces liens,
            il sortira du Site. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles
            aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 7 : Cookies
          </h3>
          <p>
            Ce Site web utilise des cookies.
            Un cookie est un fichier contenant un identifiant (une chaîne de lettres et de chiffres)
            envoyé par un serveur web vers un navigateur web et stocké par le navigateur.
            L’identifiant est alors renvoyé au serveur à chaque fois que le navigateur demande une page au serveur.
            Les cookies peuvent être « persistants » ou « de session » : un cookie persistant est stocké par le
            navigateur et reste valide jusqu’à sa date d’expiration, à moins d’être supprimé par l’utilisateur avant cette date d’expiration ;
            quant à un cookie de session, il expire à la fin de la session utilisateur, lors de la fermeture du navigateur.
            Les cookies ne contiennent en général aucune information permettant
            d’identifier personnellement un utilisateur, mais les informations personnelles q
            ue nous stockons à votre sujet peuvent être liées aux informations stockées dans les cookies et obtenues
            par les cookies. Nous utilisons des cookies de session et des cookies persistants sur le Site.

            Les noms des cookies que nous utilisons sur le Site et les objectifs dans lesquels nous les utilisons sont décrits ci-dessous:
            <ul>
              <li>
                - Votre ID de session vous permettant de rester connecté sur le Site. Par mesure de sécurité, ces cookies sont
                inutilisables au bout de 7 jours et vous devrez vous reconnecter
              </li>
              <li>
                - Votre profil d'utilisateur pour adapter et personnaliser le Site à vos besoins
              </li>
            </ul>
            Sans l'utilisation de ces données stockées, l'utilisation du Site n'est pas possible.

            Vous pouvez supprimer les cookies déjà stockés sur votre ordinateur. Exemple:
            avec Internet Explorer (version 10), vous devez supprimer le fichier
            cookies manuellement (vous pourrez trouver des instructions pour le faire ici http://support.microsoft.com/kb/278835);
            avec Firefox (version 24), vous pouvez supprimer les cookies en cliquant sur «Outils»,
            «Options», et «Confidentialité», puis en sélectionnant «Utiliser des paramètres personnalisés pour l’historique» et en cliquant sur «Montrer les cookies», puis sur «Supprimer tous les cookies»; et
            avec Chrome (version 29), vous pouvez supprimer tous les cookies en accédant au menu
            «Personnaliser et contrôler» puis en cliquant sur «Paramètres», « Montrer les paramètres avancés » et «Supprimer les données de navigation» puis «Supprimer les cookies et les données des modules d’autres Sites» avant de cliquer sur «Supprimer les données de navigation».
            Supprimer les cookies aura un impact négatif sur l’utilisation de nombreux Sites web.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 8 : Envoi de document par l'Utilisation
          </h3>
          <p>
            Le Site permet à ses membres de charger des documents et de communiquer des informations.
            L'Utilisateur permet à la société de traiter les documents et informations chargés dans le but
            de réaliser des bilans retraite individuels.
            Tout contenu chargé par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas
            charger de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice
            engagé par un tiers lésé contre le Site sera pris en charge par l'Utilisateur.
            Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par
            le Site, sans préavis.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 9 : Droit applicable et juridiction compétente
          </h3>
          <p>
            La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né
            entre les parties, les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur au mail
            contact@accompagnement-retraite-audiens.org
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConditionsUtilisation',
}
</script>
